<template>
    <footer class="footer">
        <div class="container">
            <div class="">
                <div class="row">

                    <div class="col-lg-6">
                        <img :src="`/img/SEP.png`" class="img-responsive pksf-logo" alt="PKSF">
                        <a href="/" class="logo-text">
                            Bhairab Shoe Mart
                        </a>
                        <p class="about-text">
                            Bhairab Shoe Mart is Bangladeshi's online B2C marketplace, 
                            connecting buyers with suppliers. With market share of the online B2C Classified Space in Bangladesh, 
                            The Channel focus on providing a platform to footwear producing micro-enterprises.</p>

                        <!-- <ul class="social">
                            <li v-for="(srow, key) in socialData" v-bind:key="key">
                                <a :href="`${srow.custom_field1}`" target="_blank">
                                    <i v-if="srow.title != 'map-marker'" :class="`fa fa-${srow.title}-square fa-2x`"></i>
                                    <i v-else :class="`fa fa-${srow.title} fa-2x`"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>

                    <div class="col-lg-3 ml-auto">
                        <h4 class="heading">Contact</h4>
                        <div>Holding: 01, Hazi Bahar Market,</div>
                        <div>Bhairabpur(Uttorpara),Bhairab,Kishoreganj.</div>
                        <div>Email: info@bhairabshoemart.com</div>
                        <div>Phone: +88-02-51000202</div>
                                
                    </div>

                    <div class="col-lg-2 mt-3">
                        <img :src="`/img/popi.png`" class="img-responsive popi-logo" alt="POPI">
                    </div>

                </div>
            </div>

            <div class="copyright text-center">
                <div class="">
                    <!-- <ul class="d-flex help justify-content-center">
                            <li><router-link :to="{ name: 'Privacy'}">Privacy Policy</router-link></li>
                            <li><router-link :to="{ name: ''}">Cookie Policy</router-link></li>
                            <li><router-link :to="{ name: ''}">Purchasing Policy</router-link></li>
                            <li><router-link :to="{ name: 'Terms'}">Term of Use</router-link></li>
                        </ul> -->
                    <div class="">
                        <span> Copyright © {{ currentYear }} Bhairab Shoe Mart. This platform is made possible by the support of the Sustainable Enterprise Project <a href="https://pksf-sep.org/" style="color: #2EB62C;" target="_blank">(SEP)</a>.</span>
                    </div>
                </div>
            </div>
        </div>
        <back-to-top text="">
            <svg  x="0px" y="0px"
                 width="25px" height="25px" viewBox="0 0 284.929 284.929" style="enable-background:new 0 0 284.929 284.929;"
                 xml:space="preserve">
<g>
	<path d="M282.082,195.285L149.028,62.24c-1.901-1.903-4.088-2.856-6.562-2.856s-4.665,0.953-6.567,2.856L2.856,195.285
		C0.95,197.191,0,199.378,0,201.853c0,2.474,0.953,4.664,2.856,6.566l14.272,14.271c1.903,1.903,4.093,2.854,6.567,2.854
		c2.474,0,4.664-0.951,6.567-2.854l112.204-112.202l112.208,112.209c1.902,1.903,4.093,2.848,6.563,2.848
		c2.478,0,4.668-0.951,6.57-2.848l14.274-14.277c1.902-1.902,2.847-4.093,2.847-6.566
		C284.929,199.378,283.984,197.188,282.082,195.285z"/>
</g>
</svg>
        </back-to-top>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                socialData: null,
                currentYear: new Date().getFullYear()
            };
        },
        mounted() {
            this.getSocial();
        },
        methods: {
            getSocial() {
            this.$http
                .get("cms/Social")
                .then((response) => {
                this.socialData = response.data.data;
                console.log(this.socialData)
                })
                .catch((e) => {
                this.socialData = null;
                });
            }
    
        },
    }
</script>

<style scoped>

</style>