<template>
  <div>
    <div class="sticky-top ">
      <div class="container d-flex justify-content-between px-8 py-2 text-sm ">
        <div class="d-flex">
          <div class="d-flex items-center mr-3">
            <a href="javascript:;">
              <i class="fa fa-phone phone"></i>
              <span class="ml-3">{{ contact.custom_field1 }}</span>
            </a>
          </div>
          <div class="d-flex items-center support-email">
            <a href="mailto:info@bhairabshoemart.com">
              <i class="fa fa-envelope email"></i>
              <span class="ml-4">{{ contact.custom_field2 }}</span>
            </a>
          </div>
        </div>
        <div class="d-flex items-center">
          <ul class="social">
            <li v-for="(srow, key) in socialData" v-bind:key="key">
              <a :href="`${srow.custom_field1}`" target="_blank">
                <i :class="`fa fa-${srow.title}`"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="fixed-top top-header">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link :to="{ name: 'Home' }">
            <img src="/img/logo.png" class="logo" />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="/">Home</a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                >
                 About us
                </a>
                <ul class="dropdown-menu multi-level">
                  <li class="dropdown-submenu">
                    <a
                      href="javascript:;"
                      class="dropdown-item dropdown-toggle"
                      data-toggle="dropdown"
                      >Who we are</a
                    >
                    <ul class="dropdown-menu">
                      <li v-for="(item, key) in whoWeAre" v-bind:key="key">
                        <router-link
                          :to="{
                            name: 'CMS',
                            params: { id: item.id, slug: item.slug },
                          }"
                          class="dropdown-item aa"
                          @click.native="hideMobileMenu()"
                          >{{ item.title }}</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <router-link class="dropdown-item" :to="{ name: 'News'}" @click.native="hideMobileMenu()"
                    >News & stories</router-link>
                    <router-link class="dropdown-item" :to="{ name: 'Gallery'}" @click.native="hideMobileMenu()"
                    >Photo Gallery</router-link>
                    <router-link class="dropdown-item" :to="{ name: 'Media'}" @click.native="hideMobileMenu()"
                    >Multimedia corner</router-link>
                  <router-link class="dropdown-item" :to="{ name: 'Publications'}" @click.native="hideMobileMenu()"
                    >Publications</router-link>
                  <router-link class="dropdown-item" :to="{ name: 'Innovation'}" @click.native="hideMobileMenu()"
                    >Innovation</router-link>
        
                </ul>
              </li>
              <li
                v-for="(row, key) in category"
                :key="key"
                class="nav-item dropdown"
              >
                <router-link
                  :to="{
                    name: 'Category',
                    params: { id: row.id, name: row.slug },
                  }"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >{{ row.name }} <b class="caret"></b
                ></router-link>
                <ul class="dropdown-menu multi-level">
                  <li
                    v-for="(srow, skey) in row.childs"
                    :key="skey"
                    class="dropdown-submenu"
                  >
                    <router-link
                      :to="{
                        name: 'Category',
                        params: { id: srow.id, name: srow.slug },
                      }"
                      @click.native="hideMobileMenu()"
                      class="dropdown-item dropdown-toggle"
                      data-toggle="dropdown"
                      >{{ srow.name }}</router-link
                    >
                    <ul v-if="srow.childs" class="dropdown-menu">
                      <li v-for="(ssrow, sskey) in srow.childs" :key="sskey">
                        <router-link
                        @click.native="hideMobileMenu()"
                          :to="{
                            name: 'Category',
                            params: { id: ssrow.id, name: ssrow.slug },
                          }"
                          class="dropdown-item "
                          >{{ ssrow.name }}</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Shop' }" @click.native="hideMobileMenu()"
                  >All Shops</router-link
                >
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'OfferItems' }" class="nav-link" @click.native="hideMobileMenu()"
                  >Offers</router-link
                >
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                >
                  Customer Care
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <router-link
                  @click.native="hideMobileMenu()"
                    class="dropdown-item"
                    :to="{
                      name: 'CMS',
                      params: { slug: '17-help-center' },
                    }"
                    >Help Center</router-link
                  >
                  <router-link class="dropdown-item" 
                  @click.native="hideMobileMenu()"
                  :to="{
                      name: 'CMS',
                      params: { slug: '18-order' },
                    }"
                    >Order</router-link
                  >
                  <router-link class="dropdown-item" 
                  @click.native="hideMobileMenu()"
                  :to="{
                      name: 'CMS',
                      params: { slug: '19-shipping-and-delivery' },
                    }"
                    >Shipping & Delivery</router-link
                  >
                  <router-link class="dropdown-item" 
                  @click.native="hideMobileMenu()"
                  :to="{
                      name: 'CMS',
                      params: { slug: '20-payment' },
                    }"
                    >Payment</router-link
                  >
                  <router-link class="dropdown-item"
                  @click.native="hideMobileMenu()" 
                  :to="{
                      name: 'CMS',
                      params: { slug: '21-returns-refunds' },
                    }"
                    >Returns & Refunds</router-link
                  >
                </div>
              </li>

            </ul>
          </div>
          <form class="card card-sm search-form form-inline">
            <div class="card-body row no-gutters align-items-center">
              <div class="col">
                <i class="fa fa-search h4 text-body"></i>
                <input
                  class="form-control form-control-lg form-control-borderless"
                  v-model="searchForm.keyword"
                  type="search"
                  @keyup="searchItem"
                  placeholder="Type keyword Leather Sandal"
                />
                <div
                  class="item-search-result"
                  v-show="searchOpened"
                  v-click-outside="hide"
                >
                  <ul>
                    <li v-for="(row, key) in searchedItems" v-bind:key="key">
                      <a href="javascript:;" @click="itemDetails(row.id)">
                        <div>
                          <img
                            :src="`${IMAGEURL}${row.thumb_image}`"
                            class="img-responsive"
                          />
                        </div>
                        <span>{{ row.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </form>
          <div class="ml-3 header-icons">
            <router-link v-if="!token" :to="{ name: 'Login' }"
              ><i class="fa fa-lock"></i
            ></router-link>
            <router-link v-else :to="{ name: 'Profile' }"
              ><i class="fa fa-user"></i
            ></router-link>
            <a href="javascript:;" @click="openCart" class="cart"
              ><i class="fa fa-shopping-cart"></i
            ></a>
          </div>
        </nav>
      </div>
    </div>

    <div class="cart-details-container">
      <div class="cart-details">
        <header>
          <span>Shopping Cart</span>
          <span
            class="float-right"
            @click="hideCartDetails"
            style="line-height:20px;height:20px;"
          >
            <i class="fa fa-arrow-right"></i>
          </span>
          <div v-if="cartLoading">
            <img src="/img/loading.gif" class="loading" style="height: 20px;" />
          </div>
        </header>
        <div v-if="cart.length > 0">
          <div class="cart-items">
            <div v-for="(row, key) in cart" v-bind:key="key" class="cart-item">
              <button class="close" @click="deleteCartItem(row.id)">
                <span class="fa fa-times"></span>
              </button>
              <div class="item-details">
                <div class="image">
                  <img
                    :src="`${IMAGEURL}${row.product.thumb_image}`"
                    class="img-responsive"
                  />
                </div>
                <div class="name">
                  {{ row.product.name }}
                </div>
              </div>
              <div class="price">
                <div class="item-price">
                  <span class="currency">৳</span> {{ row.price }}
                </div>
                <div class="inc-dec">
                  <button @click="removeItemQty(row.id)">
                    <i class="fa fa-minus"></i>
                  </button>
                  <span class="qty">{{ row.quantity }}</span>
                  <button @click="addItemQty(row.id)">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="item-total">
                  <span class="currency">৳</span> {{ row.total }}
                </div>
              </div>
            </div>
          </div>

          <div class="total-row">
            <div class="sub-total">
              <label>Subtotal</label>
              <span><span class="currency">৳</span> {{ subTotal }}</span>
            </div>
            <div class="place-order">
              <button
                type="button"
                @click="redirectToCheckOut()"
                class="btn btn-warning"
              >
                Place Order
              </button>
            </div>
          </div>
        </div>

        <div v-else class="text-center cart-empty">
          <i class="fa fa-shopping-cart"></i>
          <h2>Empty Cart</h2>
          <p>Your shopping cart is empty</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "app",
  computed: {
    ...mapGetters(["cart", "wishlist", "itemsCount", "subTotal"]),
  },
  data() {
    return {
      contact: null,
      whoWeAre: [],
      category: [],
      socialData: null,
      cartLoading: false,
      searchOpened: false,
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      currentLan: i18n.locale,
      menuIsOpen: false,
      redirectUrl: "",
      searchForm: {
        keyword: null,
      },
      searchedItems: null,
    };
  },
  mounted() {
    this.getContact();
    this.getWhoWeAre();
    this.getCategory();
    this.getSocial();
    this.$store.dispatch("getCart");
    this.$store.dispatch("getWishlist");
    $("body").click(function(e) {
      var className = e.target.className;
      if (
        className != "mobile-menu" &&
        className != "square-icon" &&
        className != "plus-icon"
      ) {
        $("#mobileMenu").hide();
      }
    });
    $(document).on("click", function(e) {
      var className = e.target.className;
      if (
        className != "fa fa-shopping-cart" &&
        className.search("btn-add-to-cart") < 1 &&
        className != "cart-details" &&
        $(e.target).closest(".cart-details").length === 0
      ) {
        $(".cart-details-container").removeClass("cart-in");
      }
    });
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    getContact() {
      this.$http
        .get("cms/Contact")
        .then((response) => {
          //console.log('response==============>', response);
          this.contact = response.data.data[0];
        })
        .catch((e) => {
          this.contact = null;
        });
    },
    getWhoWeAre() {
      this.$http
        .get("cms/Who-We-Are")
        .then((response) => {
          this.whoWeAre = response.data.data;
        })
        .catch((e) => {
          this.whoWeAre = [];
        });
    },
    getCategory() {
      this.$http
        .get("category")
        .then((response) => {
          this.category = response.data.data;
          console.log(this.category);
        })
        .catch((e) => {
          this.category = [];
        });
    },
    getSocial() {
      this.$http
        .get("cms/Social")
        .then((response) => {
          this.socialData = response.data.data;
          console.log(this.socialData);
        })
        .catch((e) => {
          this.socialData = null;
        });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 0) {
        $(".top-header").css("top", "0px");
      } else {
        $(".top-header").css("top", "40px");
      }
      //  console.log(this.scrollPosition);
    },
    logout() {
      localStorage.clear();
      location.href = "/";
    },
    hide() {
      this.searchOpened = false;
    },
    hideMobileMenu() {
      const screenWidth = window.screen.width;
      console.log(screenWidth)
      if(screenWidth <= 991){
        $("#navbarNav").removeClass('show');
      }
    },
    itemDetails(id) {
      this.$router.push("/item/" + id);
    },
    removeItemQty(id) {
      var payload = {
        id: id,
      };
      this.$store.dispatch("removeItemQty", payload);
    },
    async addItemQty(id) {
      var payload = {
        id: id,
      };
      const response = await this.$store.dispatch("addItemQty", payload);
      if(response.status === 'error'){
        this.flashMessage.error({ message: response.messages });
        return false;
      }
    },
    async deleteCartItem(id) {
      var payload = {
        id: id,
      };
      const response = await this.$store.dispatch("deleteFromCart", payload);
      if(response.status === 'error'){
        this.flashMessage.error({ message: response.messages });
        return false;
      }
    },
    changeLocale(locale) {
      i18n.locale = locale;
      this.currentLan = i18n.locale;
    },
    showHideMenu() {
      $("#mobileMenu").toggle();
    },
    openCart() {
      $(".cart-details-container").toggleClass("cart-in");
    },
    hideCartDetails() {
      $(".cart-details-container").removeClass("cart-in");
    },
    redirectToCheckOut() {
      this.redirectUrl = "/check-out";
      if (localStorage.getItem("token")) {
        this.cartInfoUpdate(localStorage.getItem("token"));
        //location.href = this.redirectUrl;
      } else {
        location.href = "/login?rd=check-out";
      }
    },
    searchItem() {
      $(".search-form .item-search-result").show();
      this.$http
        .post("product-search", this.searchForm)
        .then((response) => {
          this.searchedItems = response.data.data;
          this.searchOpened = true;
        })
        .catch((e) => {
          this.searchedItems = null;
        });
    },
    cartInfoUpdate(token) {
      var data = {
        session_id: localStorage.getItem("session_id"),
      };
      this.$http
        .post("cart-info-update", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (this.redirectUrl) {
            location.href = this.redirectUrl;
          } else {
            location.reload();
          }
        })
        .catch((e) => {
          if (e.response.data.message == "Unauthenticated.") {
            localStorage.clear();
            location.href = "/login?rd=check-out";
          }
        });
    },
  },
  directives: {
    ClickOutside,
  }
};
</script>
